









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_INTERNAL_ACCOUNT } from '@/modules/listOfAssets/store/internalAccount';
import { InternalAccountDetails, ChangeInternalAccount } from '@/modules/listOfAssets/types';
import { ApiResponse } from '@/components/types';

const InternalAccount = namespace('listOfAssets/internalAccount');

@Component({})
export default class InternalAccountDialog extends Vue {
  @Prop({ type: Object, required: true }) account!: InternalAccountDetails;

  @InternalAccount.Action(CHANGE_INTERNAL_ACCOUNT) changeInternalAccount!: (value: ChangeInternalAccount) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: ChangeInternalAccount = {
    finance_account_id: this.account.id,
    list_of_assets_id: this.account.listOfAssetsId,
    note: this.account.note
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        finance_account_id: this.account.id,
        list_of_assets_id: this.account.listOfAssetsId,
        note: this.account.note
      };

      return;
    }

    setTimeout(() => {
      this.error = null;

      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  save() {
    this.error = null;

    return this.changeInternalAccount(this.value);
  }
}
